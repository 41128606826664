import LoadingButton from "@mui/lab/LoadingButton";
import { buttonStyles } from "./button-styles";

interface ButtonProps {
  text: string;
  className?: string;
  variant?: "text" | "outlined" | "contained" | "smallGrey" | "secondary";
  type?: "button" | "submit" | "reset";
  styles?: { [key: string]: string | number };
  loading?: boolean;
  onClick?: () => void;
}

const defaultStyles = {
  "text-transform": "none",
  "box-shadow": "none",
};

const customVariants = ["smallGrey", "secondary"];

export const Button = ({
  text,
  className = "",
  variant = "contained",
  type = "button",
  styles = {},
  loading = false,
  onClick = () => {},
}: ButtonProps) => {
  const muiVariant = customVariants.includes(variant) ? "contained" : variant;
  return (
    <LoadingButton
      type={type}
      className={className}
      onClick={onClick}
      variant={muiVariant}
      loading={loading}
      loadingPosition="center"
      sx={{ ...defaultStyles, ...buttonStyles[variant], ...styles }}
    >
      <span>{text}</span>
    </LoadingButton>
  );
};
