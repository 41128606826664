import { colors } from './styles';

export const generalTableStyles = {
  "& .MuiTableCell-root": {
    borderBottom: `1px solid ${colors.borderGrey}`, // Light gray border between rows
  },
  "& .MuiTableCell-head": {
    fontWeight: "bold",
  },
  "& .MuiTableRow-root:last-child": {
    borderBottom: "none", // Remove bottom border from last row
  },
  "& .MuiTableContainer-root": {
    border: "none", // Remove outer border
  },
  "& .MuiPaper-root": {
    boxShadow: "none", // Remove paper shadow for a clean look
  },
};