import React from "react";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { colors } from '../../constants/styles';
import FieldLabel from '../FieldLabel';

interface RadioButtonsOption {
  label: string;
  value: string | boolean;
}

interface RadioButtonsProps {
  id: string;
  name: string;
  label: string;
  options: RadioButtonsOption[];
  defaultValue?: string;
  value: string | boolean;
  isRequired?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const RadioButtons = ({
  id,
  name,
  label,
  options,
  defaultValue = "",
  value,
  isRequired = false,
  onChange,
}: RadioButtonsProps) => {
  return (
    <FormControl>
      <FieldLabel isRequired={isRequired}>{label}</FieldLabel>
      <RadioGroup
        aria-labelledby={id}
        defaultValue={defaultValue}
        name={name}
        value={value}
        onChange={onChange}
        row={true}
      >
        {options.map((option, index) => (
          <FormControlLabel
            key={index}
            value={option.value}
            control={
              <Radio
                sx={{
                  color: colors.primary,
                }}
              />
            }
            label={option.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
