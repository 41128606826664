import { ReactNode } from "react";
import {
  FormControl,
  InputBase,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { colors } from "../../constants/styles";
import FieldLabel from '../FieldLabel';

interface ItemSelect {
  label: string;
  value: string | number;
}

interface InputSelectProps {
  items: ItemSelect[];
  selectedValue: string | number;
  label?: string;
  id: string;
  containerClassName?: string;
  className?: string;
  isRequired?: boolean;
  error?: boolean;
  onChange: (event: SelectChangeEvent<string>, child: ReactNode) => void;
}

export const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: 8,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid",
    borderColor: colors.borderGrey,
    fontSize: 14,
    padding: "8px 12px",
    "&:focus": {
      borderColor: colors.primary,
      boxShadow: "none",
    },
  },
  ".MuiInputLabel-root": {
    display: "none",
  },
}));

export const InputSelect = ({
  items,
  selectedValue,
  label,
  id,
  containerClassName = "",
  className = "",
  isRequired = false,
  error = false,
  onChange,
}: InputSelectProps) => {

  return (
    <div className={containerClassName}>
      {label && <FieldLabel isRequired={isRequired}>{label}</FieldLabel>}
      <FormControl className={className}>
        <Select
          labelId={`${id}-label`}
          id={id}
          value={selectedValue}
          error={error}
          label={label}
          onChange={onChange}
          input={<BootstrapInput />}
        >
          {
            !isRequired && (
              <MenuItem value="">
                <em>{label}</em>
              </MenuItem>
            )
          }
          {items.map((item) => {
            return (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};
