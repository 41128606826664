import { createTheme } from "@mui/material/styles";
import { colors } from './constants/styles';

// Create a custom theme with Inter as the default font
const theme = createTheme({
  typography: {
    fontFamily:
      'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
  },
  palette: {
    primary: {
      main: colors.primary,
    },
    grey: {
      main: colors.grey,
    }
  },
});

export default theme;
