import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { colors } from "../../constants/styles";
import MobileNavigationModal from "../MobileNavigationModal";
import CustomLink from "../CustomLink";
import { HomeIcon } from '../../img/react-icons/home';
import { TableIcon } from '../../img/react-icons/table';
import { SettingIcon } from '../../img/react-icons/setting';

interface SideBarProps {
  children: React.ReactNode;
}

export const SideBar = ({ children }: SideBarProps) => {
  const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();

  const routes = [
    {
      path: "/events",
      name: "events",
      label: t("Events"),
      icon: TableIcon,
    },
    {
      path: "/location",
      name: "location",
      label: t("Location"),
      icon: HomeIcon,
    },
    {
      path: "/settings",
      name: "settings",
      label: t("Settings"),
      icon: SettingIcon,
    },
  ];

  const toggleMobileMenu = () => {
    setIsMobileMenuOpened(!isMobileMenuOpened);
  };

  const currentRoute = routes.find((route) => route.path === location.pathname);

  return (
    <div className="block sm:flex">
      <div className="w-2/12 hidden sm:flex border-r border-gray50">
        <div className="sm:flex h-screen flex-col">
          <div className="border-b border-gray50">
            <Link className="flex items-center mt-2 mb-2" to="/events">
              <img
                className="w-[53px] h-[53px] ml-4"
                src={require("../../img/logo_navigation.png")}
                alt="Logo"
              />
              <p className="ml-4 font-sans font-semibold">Admin-Bereich</p>
            </Link>
          </div>
          <div className="flex h-full justify-between flex-col">
            <ul className="flex flex-col pl-4">
              {routes.map((route) => {
                const chosen = location.pathname === route.path;
                const linkClassName = classNames("m-2", "text-base", {
                  "text-primary": chosen,
                });
                const Icon = route.icon;
                return (
                  <li key={route.name} className="my-1">
                    <div className="flex">
                      <span className="mt-2">
                        <Icon fill={chosen ? colors.primary : colors.black} />
                      </span>
                      <Link className={linkClassName} to={route.path}>
                        {route.label}
                      </Link>
                    </div>
                  </li>
                );
              })}
            </ul>
            <div className="flex justify-center items-center">
              <div className="flex flex-col justify-start bg-highlight p-4 m-4 rounded-lg">
                <p>
                  {t(
                    "If you have any problems, technical errors or new ideas, please feel free to contact us by email. We usually respond the same day."
                  )}
                </p>
                <CustomLink
                  to="mailto:support@meinklub.app"
                  title="support@meinklub.app"
                  className="mt-2"
                  isExternal={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full block sm:hidden bg-primary">
        <AppBar position="fixed" sx={{ background: colors.primary }}>
          <Toolbar variant="dense">
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={toggleMobileMenu}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" component="div">
              {currentRoute?.label}
            </Typography>
          </Toolbar>
        </AppBar>
        <MobileNavigationModal
          routes={routes}
          isOpen={isMobileMenuOpened}
          pathname={location.pathname}
          toggleModal={toggleMobileMenu}
        />
      </div>
      <section className="w-full sm:w-10/12 mt-14 sm:mt-0">{children}</section>
    </div>
  );
};
