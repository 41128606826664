import deLocale from "date-fns/locale/de";
import { AmendmentType, ICreateEventDTO, IEvent } from "../types/events";
import { format, setDay } from "date-fns";
import { logger } from "../util/logger";
import { daysInWeek } from "../constants/date";
import { daysInWeekStringToNumber } from "../constants/date";
import { doc } from "firebase/firestore";
import { Collections } from "../types/collections";
import { db } from "../firebase";

const getDateFnsLocale = () => {
  // TODO: add more languages
  return deLocale;
};

// returning date in format 8. August
export const getEventDate = (start_date: string, isFullDayFormat?: boolean) => {
  const formatting = isFullDayFormat ? "EEEE" : "EEEEEE";
  const locale = getDateFnsLocale();
  const options = { locale, weekStartsOn: 1 };
  return format(new Date(start_date), `${formatting}, d.M`, options);
};

export const getEventDateString = (
  event: IEvent,
  isFullDayFormat?: boolean
) => {
  try {
    const locale = getDateFnsLocale();
    const options = { locale, weekStartsOn: 1 };
    const { start_time } = event;

    const formatting = isFullDayFormat ? "EEEE" : "EEEEEE";
    // basic case when event is one time, we are returning just event.start_date
    // format 8. August
    if (!event.recurring_event || !event.days?.length) {
      const dateEventString = getEventDate(event.start_date);
      return `${dateEventString}, ${start_time}`;
    }

    // regular events
    if (event.days.length) {
      // event once per week
      // format Mittwochs - 17:30
      if (event.days.length === 1) {
        const day = format(setDay(new Date(), event.days[0]), "EEEE", options);
        return `${day}s, ${start_time}`;
      }

      if (event.days.length <= 4) {
        // event three times per week
        // format Mo, Di, Mi
        const range = event.days.reduce(
          (acc, day, index) =>
            (acc += format(
              setDay(new Date(), day),
              index + 1 === event.days.length ? formatting : `${formatting}, `,
              options
            )),
          ""
        ) as string;
        return `${range}, ${start_time}`;
      }

      // days range
      // format Mo - Sa
      const sortedDays = event.days.sort((a, b) => a - b);
      const rageStart = format(
        setDay(new Date(), sortedDays[0]),
        formatting,
        options
      );
      const rageEnd = format(
        setDay(new Date(), sortedDays[sortedDays.length - 1]),
        formatting,
        options
      );
      return `${rageStart} - ${rageEnd}, ${start_time}`;
    }
  } catch (err) {
    logger.error("Error on getting event date string", {
      err,
      id: event.id,
      start_date: event.start_date,
      end_date: event.end_date,
      recurring_event: event.recurring_event,
    });
  }
  return "";
};

export const createDaysObject = (arr: number[]) => {
  const object: { [key: string]: boolean } = {
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  };

  arr.forEach((dayNumber) => {
    object[daysInWeek[dayNumber]] = true;
  });
  return object;
};

export const transformDateObjToArr = (obj: { [key: string]: boolean }) => {
  const arr = [];
  for (const key in obj) {
    if (obj[key]) {
      arr.push(daysInWeekStringToNumber[key]);
    }
  }

  return arr.sort((a, b) => a - b);
};

export const createAgeRange = (min: number, max: number) => {
  const result = [];
  let counter = max - min;
  while (counter >= 0) {
    result.push(max - counter);
    counter -= 1;
  }
  return result;
};

export const wordsIndexing = (item: string) => {
  try {
    const indexing = [];
    const words = item.split(" ");

    for (let i = 0; i <= words.length; i++) {
      const result = [];
      if (!words[i]) continue;

      for (let j = 1; j <= words[i].length; j++) {
        result.push(words[i].substring(0, j));
      }

      indexing.push(result);
    }

    return indexing.flat();
  } catch (err) {
    logger.error("ERROR in word indexing", { item, err });
    return [];
  }
};

export const defaultIndexing = (item: string) => {
  try {
    const indexing = [];
    for (let i = 1; i <= item.length; i++) {
      indexing.push(item.substring(0, i) || "");
    }
    return indexing;
  } catch (err) {
    logger.error("ERROR in indexing", { item, err });
    return [];
  }
}

export const eventIndexing = (
  eventName: string | undefined = "",
  locationName: string | undefined = "",
  descriptionWithoutEmoji: string | undefined = "",
  categoryName: string | undefined = "",
) => {
  const name = eventName.toLowerCase();
  const location = locationName.toLowerCase();
  const description = descriptionWithoutEmoji.toLowerCase();
  const category = categoryName.toLowerCase();
  // Indexing by different words
  const nameIndexing = wordsIndexing(name);
  const descriptionIndexing = wordsIndexing(description);
  const locationIndexing = wordsIndexing(location);
  const wordsCategoryIndexing = wordsIndexing(category);
  // indexing by phrases
  const namePhrases = defaultIndexing(name);
  const descriptionPhrases = defaultIndexing(description);
  const locationPhrases = defaultIndexing(location);
  const categoryPhrases = defaultIndexing(category);

  return {
    indexing: [
      ...nameIndexing,
      ...descriptionIndexing,
      ...locationIndexing,
      ...wordsCategoryIndexing,
      ...namePhrases,
      ...descriptionPhrases,
      ...locationPhrases,
      ...categoryPhrases,
    ],
    nameIndexing,
    descriptionIndexing,
    locationIndexing,
  };
};

export const getIndexing = (event: ICreateEventDTO) => {
  logger.info("Started creating indexing for events");
  const eventIndex = eventIndexing(
    event.names.de,
    event.location_name,
    event.descriptionWithoutEmoji,
    event.category_name,
  );

  return {
    id: event.id,
    eventRef: doc(db, Collections.Events, `${event.id}`),
    isActive: event.isActive,
    locationId: event.location.id,
    ...eventIndex,
  };
};

export const getIsCanceled = (event: IEvent, date: string) => {
  if (!event.amendments || !event.amendments.length) {
    return false;
  }
  const cancelAmendmentForDay = event.amendments.find(
    (amendment) =>
      amendment.effected_date === date &&
      amendment.type === AmendmentType.cancel
  );
  return !!cancelAmendmentForDay;
};
