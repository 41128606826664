import Button from "../../components/Button";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import { FormEvent, useState } from "react";
import AuthContainer from "../../components/AuthContainer";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { getFunctions, httpsCallable } from "firebase/functions";
import app, { functionsRegion } from "../../firebase";
import { useUserProfile } from "../../context/UserProfileContext";
import { useLogout } from "../../hooks/useLogout";
import { format } from "date-fns";
import { logger } from "../../util/logger";
import { InputSelect } from '../../components/InputSelect/InputSelect';
import { SelectChangeEvent } from '@mui/material';

type DateChangeField = "startDate" | "endDate";
const functions = getFunctions(app, functionsRegion);

export const SchoolStatistic = () => {
  const { userSchoolProfile } = useUserProfile();
  const { logoutUser } = useLogout();

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDateError, setStartDateError] = useState("");
  const [endDateError, setEndDateError] = useState("");
  const firstCode = userSchoolProfile ? userSchoolProfile.school_codes[0] : "";
  const [selectedCode, setSelectedCode] = useState(firstCode);
  const [loading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const downloadCSV = (csvString: string, filename: string) => {
    // Convert CSV string to a Blob
    const blob = new Blob([csvString], { type: "text/csv" });

    // Create a downloadable URL
    const url = window.URL.createObjectURL(blob);

    // Create a temporary <a> element
    const a = document.createElement("a");
    a.href = url;
    a.download = filename; // File name for the download
    document.body.appendChild(a);
    a.click(); // Trigger download

    // Clean up
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!userSchoolProfile) return;
    if (!startDate) {
      toast.error(t("From field is empty"));
      return;
    }

    if (!endDate) {
      toast.error(t("To field is empty"));
      return;
    }

    try {
      setIsLoading(true);
        const generateTimeReport = httpsCallable(
          functions,
          "generateTimeReport"
        );
      const resp = await generateTimeReport({
        startDate,
        endDate,
        schoolCode: selectedCode,
      });

      const dateFormatting = "yyyy-MM-dd";
      downloadCSV(
        resp.data as string,
        `report-${selectedCode}-${format(
          startDate,
          dateFormatting
        )}-${format(endDate, dateFormatting)}.csv`
      );
    } catch (error) {
      const errorMessage = (error as Error).message;
      logger.error("Error downloading CSV:", error);
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const resetErrors = () => {
    setStartDateError("");
    setEndDateError("");
  };

  const onDateChange =
    (field: DateChangeField) => (value: dayjs.Dayjs | null) => {
      if (value) {
        resetErrors();

        const isEndDateSetting =
          startDate &&
          field === "endDate" &&
          dayjs(value.toDate().setHours(2)).isBefore(dayjs(startDate));

        const isStartDateSetting =
          endDate && field === "startDate" && value.isAfter(dayjs(endDate));

        if (isEndDateSetting || isStartDateSetting) {
          const errorMessage = t("End date should be after start date");
          toast.error(errorMessage);
          setStartDateError(errorMessage);
          setEndDateError(errorMessage);
        }

        if (field === "startDate") {
          setStartDate(new Date(value.toDate().setHours(2)).toISOString());
          return;
        }

        setEndDate(new Date(value.toDate().setHours(2)).toISOString());
      }
    };

  const onChangeSchoolCode = (event: SelectChangeEvent<string>) => {
    setSelectedCode(event.target.value);
  };

  if (!userSchoolProfile) {
    return (
      <section className="bg-primary h-screen">
        <div className="container mx-auto min-h-full flex items-center justify-center bg-primary">
          <AuthContainer hideLogo={true}>
            <div className="lg:w-8/12 sm:w-11/12">
              <div className="flex justify-end">
                <Button
                  text={t("Log out")}
                  className="w-3/12"
                  onClick={logoutUser}
                />
              </div>
            </div>
          </AuthContainer>
        </div>
      </section>
    );
  }

  const schoolCodesItems = userSchoolProfile.school_codes.map((code) => ({ label: code, value: code }))
  return (
    <section className="bg-primary h-screen">
      <div className="container mx-auto min-h-full flex items-center justify-center bg-primary">
        <AuthContainer hideLogo={true}>
          <div className="lg:w-8/12 sm:w-11/12">
            <form onSubmit={onSubmit} className="w-full mb-4">
              <div className="flex justify-between">
                <InputSelect
                  id="school-code-select"
                  items={schoolCodesItems}
                  selectedValue={selectedCode}
                  onChange={onChangeSchoolCode}
                />
                <Button
                  text={t("Log out")}
                  className="w-3/12"
                  onClick={logoutUser}
                />
              </div>
              <div className="flex justify-between mt-4 mb-5">
                <div className="w-1/2 pr-5">
                  <DatePicker
                    className="w-full"
                    value={dayjs(startDate)}
                    onChange={onDateChange("startDate")}
                    label={t("From")}
                    slotProps={{
                      textField: () => ({
                        error: Boolean(startDateError),
                      }),
                    }}
                  />
                </div>
                <div className="w-1/2 pl-5">
                  <DatePicker
                    className="w-full"
                    value={dayjs(endDate)}
                    onChange={onDateChange("endDate")}
                    label={t("To")}
                    slotProps={{
                      textField: () => ({
                        error: Boolean(endDateError),
                      }),
                    }}
                  />
                </div>
              </div>
              <div className="flex justify-center items-center">
                <Button
                  type="submit"
                  text={t("Generate")}
                  className="w-full"
                  loading={loading}
                />
              </div>
            </form>
            <ToastContainer />
          </div>
        </AuthContainer>
      </div>
    </section>
  );
};
