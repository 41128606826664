import { useEffect, useState, createContext, ReactElement, useContext } from 'react';
import { auth } from "../firebase";
import { Nullable } from '../types/utility';
import { User, onAuthStateChanged } from "firebase/auth";
import { logger } from '../util/logger';
import { useUserProfile } from './UserProfileContext';

interface IAuthProvider {
  children: ReactElement;
}

export type UserRole = "LocationAdmin" | "SchoolAdmin";

interface IAuthContext {
  user: Nullable<User>;
  isLoading: boolean;
  userRole: Nullable<UserRole>;
  resetUserAuth: () => void;
}

const defaultAuthStore = {
  user: null,
  isLoading: true,
  userRole: null,
  resetUserAuth: () => {},
};

export const AuthContext = createContext<IAuthContext>(defaultAuthStore);

export const AuthProvider = ({ children }: IAuthProvider) => {
  const [user, setUser] = useState<Nullable<User>>(defaultAuthStore.user);
  const [userRole, setUserRole] =
    useState<Nullable<UserRole>>(defaultAuthStore.userRole);
  const [isLoading, setIsLoading] = useState(defaultAuthStore.isLoading);
  const { getUserProfile } = useUserProfile();

  const setLoadingWithDelay = (loading: boolean, delay = 1500) => {
    setTimeout(() => setIsLoading(loading), delay);
  }

  const checkUser = async (data: Nullable<User>) => {
    if (!data) {
      setLoadingWithDelay(false);
      return;
    }

    try {
      const idTokenResult = await data?.getIdTokenResult();
      let role = idTokenResult.claims.role as UserRole;

      if (!role) {
        role = 'LocationAdmin';
      }
      setUserRole(role);

      await getUserProfile(data, role);
      setUser(data);
    } catch (err) {
      logger.error("Error in Auth store checkUser subscriber", err);
    } finally {
      // wait second to show app loading animation
      setLoadingWithDelay(false);
    }
  };

  const resetUserAuth = () => {
    setUser(null);
    setUserRole(null);
  }

  useEffect(() => {
    onAuthStateChanged(auth, checkUser);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider value={{ user, isLoading, userRole, resetUserAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
