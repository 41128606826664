import { Gender } from '../types/events';
import { EventFormFields, IEventTypes, LocationFormFields } from '../types/forms';

export const generateGenderOptions = () => [
  { value: Gender.All, label: "Alle" },
  { value: Gender.girl, label: "Mädchen" },
  { value: Gender.boy, label: "Jungs" },
  { value: Gender.minta, label: "Minta" },
];

export const generateAccessibilityOptions = () => [
  { value: true, label: "Ja" },
  { value: false, label: "Nein" },
];

export const generateRecurringOptions = () => [
  { value: "regular", label: "Regelmäßig" },
  { value: "one-time", label: "Einmalig" },
];

export const generateAgeOptions = (from: number, to: number) => {
  const options = [];
  for (let i = from; i <= to; i++) {
    options.push({ value: i.toString(), label: i.toString() });
  }
  return options;
};

export const generateDayOptions = () => [
  { value: 1, label: "Mo" },
  { value: 2, label: "Di" },
  { value: 3, label: "Mi" },
  { value: 4, label: "Do" },
  { value: 5, label: "Fr" },
  { value: 6, label: "Sa" },
  { value: 7, label: "So" },
];

export const generatePriceOptions = () => [
  { value: "free", label: "Kostenlos" },
  { value: "10", label: "Wählen Sie den Preis" },
];

export const generateKlubForSchoolOptions = () => [
  { value: true, label: "Einschließen" },
  { value: false, label: "Ausschließen" },
];

export const requiredCreateEventFields: EventFormFields[] = [
  "name",
  "description",
  "start_date",
  "end_date",
  "start_time",
  "end_time",
  "minAge",
  "maxAge",
];

export const requiredLocationFields: LocationFormFields[] = [
  "name",
  "street",
  "city",
  "postal_code",
  "area",
  "gender",
  "openingTime",
  "minAge",
  "maxAge",
  "phone",
  "email",
  "manager",
  "contactPerson",
  "location_type",
];

export const locationTypesList = [
  { value: IEventTypes.jugendzentrum, label: "Jugendzentrum" },
  { value: IEventTypes.theater, label: "Theater" },
  { value: IEventTypes.musikschule, label: "Musikschule" },
  { value: IEventTypes.tanzstudio, label: "Tanzstudio" },
  { value: IEventTypes.kulturverein, label: "Kulturverein" },
  { value: IEventTypes.natur, label: "Natur" },
  { value: IEventTypes.partei, label: "Partei" },
  { value: IEventTypes.berufsorientierung, label: "Berufsorientierung" },
  { value: IEventTypes.musikclub, label: "Musikclub" },
  { value: IEventTypes.abenteuerspielplatz, label: "Abenteuerspielplatz" },
  { value: IEventTypes.makerSpace, label: "Maker Space" },
];

export const MIN_AGE = 10;
export const MAX_AGE = 27;

export const INSTAGRAM_BASE_URL = "https://www.instagram.com/";
export const TIKTOK_BASE_URL = "https://www.tiktok.com/@";