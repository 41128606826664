import { useAuth } from '../context/AuthContext';
import { auth } from "../firebase";
import { signOut } from "firebase/auth";
import { logger } from "../util/logger";
import { useUserProfile } from '../context/UserProfileContext';

export const useLogout = () => {
    const { resetUserAuth } = useAuth();
    const { removeUserInfo } = useUserProfile();

    const logoutUser = () => {
        try {
            signOut(auth);
            resetUserAuth();
            removeUserInfo();
        } catch(err) {
            logger.error("Error in Auth store logout", err);
        }
    }

    return {
      logoutUser,
    };
}