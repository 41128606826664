
export const colors = {
  primary: "#8648E6",
  primaryDark: "#7441C5",
  black: "#000000",
  white: "#FFFFFF",
  grey: "#888E93",
  blue: "#0000FF",
  mint: "#76DACE",
  label: "#0009",
  red: "#FCDAD4",
  lightGrey: "#F9F7F7",
  green: "#E0EDD3",
  gray50: "#DEE2E5",
  highlight: "#EDF1F4",
  borderGrey: "#DFE2E5",
  required: "#FF0000",
};
