import { DatePicker, DatePickerProps } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";
import { colors } from "../../constants/styles";
import FieldLabel from '../FieldLabel';

interface IProps extends DatePickerProps<Dayjs, false> {
    isRequired?: boolean;
    placeholder: string;
}

export const dataPickerSx = {
  "& .MuiOutlinedInput-input": {
    padding: "8.5px 0 8.5px 14px",
    fontSize: 14,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "2px solid",
    borderColor: colors.borderGrey,
    borderRadius: 2,
    "&:focus": {
      border: "2px solid",
      borderColor: colors.primary,
      boxShadow: "none",
    },
  },
};

export const CustomDatePicker = ({isRequired = false, placeholder, ...props }: IProps) => {
  return (
    <>
      <FieldLabel isRequired={isRequired}>{placeholder}</FieldLabel>
      <DatePicker {...props} sx={dataPickerSx} />
    </>
  );
};
