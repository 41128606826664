import { ChangeEvent, MouseEventHandler, useEffect, useState } from "react";
import PageContainer from "../../components/PageContainer";
import PageHeader from "../../components/PageHeader";
import { useTranslation } from "react-i18next";
import InputField from "../../components/InputField";
import { InputSelect } from "../../components/InputSelect/InputSelect";
import { useCategories } from "../../context/CategoriesContext";
import { FormControlLabel, FormGroup, SelectChangeEvent } from "@mui/material";
import RadioButtons from "../../components/RadioButtons";
import {
  generateDayOptions,
  generateGenderOptions,
  generateKlubForSchoolOptions,
  generatePriceOptions,
  generateRecurringOptions,
  requiredCreateEventFields,
} from "../../constants/forms";
import CheckboxSelect from "../../components/CheckboxSelect";
import dayjs from "dayjs";
import classNames from "classnames";
import ImagePicker from "../../components/ImagePicker";
import { useFilePicker } from "use-file-picker";
import {
  FileAmountLimitValidator,
  FileTypeValidator,
} from "use-file-picker/validators";
import { useNavigate } from "react-router-dom";
import { useLocations } from "../../context/LocationsContext";
import { useEvents } from "../../context/EventsContext";
import { v4 as uuidv4 } from "uuid";
import { toast, ToastContainer } from "react-toastify";
import { MultiInputTimeRangeField } from "@mui/x-date-pickers-pro/MultiInputTimeRangeField";
import { FieldChangeHandler } from "@mui/x-date-pickers/internals";
import { DateRange, TimeRangeValidationError } from "@mui/x-date-pickers-pro";
import {
  EventFormFields,
  ICreateEventFrom,
  SelectItem,
} from "../../types/forms";
import {
  defaultEventFormState,
  defaultFormErrorState,
  generateSmallRandomId,
  transformItemsForSelect,
} from "../../helpers/formHelpers";
import Button from "../../components/Button";
import {
  getStorageImageURL,
  uploadImage,
} from "../../helpers/firestoreHelpers";
import { ImageCustomValidator } from "../../helpers/ImageCustomValidator";
import { Gender } from "../../types/events";
import Checkbox from "../../components/Checkbox";
import FieldLabel from "../../components/FieldLabel";
import CustomDatePicker from "../../components/CustomDatePicker";
import { dataPickerSx } from '../../components/CustomDatePicker/CustomDatePicker';

interface CreateEventProps {
  eventId: string;
}

const getDayJsTimeFromString = (time: string) => {
  return dayjs()
    .startOf("month")
    .set("hour", +time.split(":")[0] || 0)
    .set("minute", +time.split(":")[1] || 0);
};

const defaultMargin = "mb-4";
const defaultWidth = "w-4/5";

export const CreateEvent = ({ eventId }: CreateEventProps) => {
  const [formState, setFormState] = useState(defaultEventFormState());
  const [formErrorState, setFormErrorState] = useState(defaultFormErrorState());
  const [formLoading, setFormLoading] = useState(false);
  const { t } = useTranslation();
  const { categories } = useCategories();
  const { location } = useLocations();
  const { createEvent, events } = useEvents();
  const { openFilePicker, filesContent } = useFilePicker({
    accept: "image/*",
    multiple: false,
    readAs: "DataURL",
    validators: [
      new FileAmountLimitValidator({ max: 1 }),
      new FileTypeValidator(["png", "jpg", "webp"]),
      new ImageCustomValidator(),
    ],
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (eventId && location) {
      const event = events.find((event) => event.id === eventId);
      const locationMinAge = location.ageRules
        ? `${location.ageRules.min}`
        : "0";
      const locationMaxAge = location.ageRules
        ? `${location.ageRules.max}`
        : "0";
      if (event) {
        const image_url =
          event.image && event.image.path
            ? getStorageImageURL(event.image.path)
            : "";

        const image = {
          url: image_url,
          path: event.image?.path || "",
        };

        const data = {
          name: event.names.de!,
          category: event.category,
          description: event.descriptions.de!,
          gender: event.gender ? event.gender : Gender.All,
          minAge: event.ageRules?.min
            ? `${event.ageRules.min}`
            : locationMinAge,
          maxAge: event.ageRules?.max
            ? `${event.ageRules.max}`
            : locationMaxAge,
          recurring_event: event.recurring_event,
          start_date: event.start_date,
          end_date: event.end_date,
          price: event.cost,
          days: event.days,
          location: event.location,
          start_time: event.start_time,
          end_time: event.end_time,
          lgbtqi: Boolean(event.lgbtqi),
          isSchoolEvent: event.isSchoolEvent || true,
          image,
          usersInEvent: event.usersInEvent || [],
        };
        setFormState(data);
      }
    }
  }, [events, eventId, location]);

  useEffect(() => {
    if (location && !eventId) {
      const newStateWithLocation = {
        ...formState,
        location: location,
      };
      if (location.ageRules && location.ageRules.min) {
        newStateWithLocation.minAge = `${location.ageRules.min}`;
      }
      if (location.ageRules && location.ageRules.max) {
        newStateWithLocation.maxAge = `${location.ageRules.max}`;
      }
      setFormState({ ...newStateWithLocation });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleFormChange =
    (field: EventFormFields) => (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      const { value } = e.target;

      if (field === "lgbtqi") {
        setFormState({ ...formState, [`${field}`]: !formState.lgbtqi });
        return;
      }

      setFormState({ ...formState, [`${field}`]: value });
    };

  const onDateChange =
    (field: EventFormFields) => (value: dayjs.Dayjs | null) => {
      if (value) {
        resetError(field);

        const isEndDateSetting =
          formState.start_date &&
          field === "end_date" &&
          dayjs(value.toDate().setHours(2)).isBefore(
            dayjs(formState.start_date)
          );

        const isStartDateSetting =
          formState.end_date &&
          field === "start_date" &&
          value.isAfter(dayjs(formState.end_date));

        if (isEndDateSetting || isStartDateSetting) {
          const errorMessage = t("End date should be after start date");
          toast.error(errorMessage);
          setFormErrorState({
            ...formErrorState,
            start_date: errorMessage,
            end_date: errorMessage,
          });
          formErrorState[field] = t(errorMessage);
        }

        setFormState({
          ...formState,
          // set hours to 2 to avoid timezone issues
          [`${field}`]: new Date(value.toDate().setHours(2)).toISOString(),
        });
      }
    };

  const onTimeChange =
    (
      field: EventFormFields
    ): FieldChangeHandler<DateRange<dayjs.Dayjs>, TimeRangeValidationError> =>
    (value) => {
      if (value) {
        const start_minutes = value[0]?.minute() || 0;
        const end_minutes = value[1]?.minute() || 0;
        const start_minutes_string =
          start_minutes < 10 ? `0${start_minutes}` : `${start_minutes}`;
        const end_minutes_string =
          end_minutes < 10 ? `0${end_minutes}` : `${end_minutes}`;
        setFormState({
          ...formState,
          start_time: `${value[0]?.hour()}:${start_minutes_string}`,
          end_time: `${value[1]?.hour()}:${end_minutes_string}`,
        });
      }
    };

  const resetError = (field: string) => {
    if (field === "minAge" || field === "maxAge") {
      setFormErrorState({ ...formErrorState, minAge: "", maxAge: "" });
      return;
    }

    if (field === "start_date" || field === "end_date") {
      setFormErrorState({ ...formErrorState, start_date: "", end_date: "" });
      return;
    }

    setFormErrorState({ ...formErrorState, [`${field}`]: "" });
  };

  const onSelectChange =
    (selectName: EventFormFields) => (event: SelectChangeEvent<string>) => {
      event.preventDefault();
      resetError(selectName);
      const { value } = event.target;

      if (selectName === "recurring_event") {
        setFormState({ ...formState, [`${selectName}`]: value === "regular" });
        return;
      }

      if (selectName === "category") {
        setFormState({
          ...formState,
          category: categories.find((cat) => cat.id === value)!,
        });
        return;
      }

      if (selectName === "days") {
        const selectedDay = +value;
        const newDays = [...formState.days];
        const selectedDaysValues = newDays.includes(selectedDay)
          ? newDays.filter((day) => day !== selectedDay)
          : [...newDays, selectedDay];
        setFormState({ ...formState, days: selectedDaysValues });
        return;
      }

      // check if age range is right
      if (selectName === "minAge" || selectName === "maxAge") {
        const minAge = selectName === "minAge" ? value : formState.minAge;
        const maxAge = selectName === "maxAge" ? value : formState.maxAge;

        if (minAge && maxAge && Number(minAge) > Number(maxAge)) {
          const message = t("Min age should be less than max age");
          toast.error(t(message));
          setFormErrorState({
            ...formErrorState,
            minAge: message,
            maxAge: message,
          });
        }
      }

      setFormState({ ...formState, [`${selectName}`]: value });
    };

  const validateForm = () => {
    let isValid = true;
    requiredCreateEventFields.forEach((field) => {
      if (!formState[field as keyof ICreateEventFrom]) {
        formErrorState[field] = "Required";
        isValid = false;
      }
    });

    !isValid && toast.error(t("Required fields is not filled"));

    // check the date range
    if (formErrorState.start_date || formErrorState.end_date) {
      isValid = false;
      toast.error(t("End date should be after start date"));
    }

    // check if min age is less than max age
    if (formErrorState.minAge || formErrorState.maxAge) {
      isValid = false;
      toast.error(t("Min age should be less than max age"));
    }

    return isValid;
  };

  const onSave: MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    setFormLoading(true);
    const id = eventId || `EVBE-${uuidv4()}`;
    const path = await uploadImage(
      `images/events/${id}-${generateSmallRandomId()}`,
      formState.image.path,
      filesContent,
      toast
    );
    await createEvent(id, formState, path);
    setFormLoading(false);
    navigate("/events");
  };

  const onBack = () => {
    navigate("/events");
  }

  const onFileChange: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    openFilePicker();
  };

  const selectCategoryItems = transformItemsForSelect(
    categories as SelectItem[]
  );

  const start_time = getDayJsTimeFromString(formState.start_time);
  const end_time = getDayJsTimeFromString(formState.end_time);

  return (
    <>
      <PageHeader
        title={eventId ? formState.name : t("Create event")}
        btnTitle={t("Save")}
        actionLoading={formLoading}
        hideBtnOnMobile={true}
        onBtnClick={onSave}
      >
        <div className="w-full hidden sm:flex justify-end">
          <Button
            text={t("Back")}
            loading={formLoading}
            variant="secondary"
            onClick={onBack}
          />
        </div>
      </PageHeader>
      <PageContainer>
        <form className="w-full flex flex-col sm:flex-row justify-between">
          <div className="w-full sm:w-1/2">
            <div className="mb-8">
              <InputField
                required={true}
                containerClassNames={defaultMargin}
                className="w-4/5"
                id="name-field"
                value={formState.name}
                type="text"
                label={t("Event name")}
                onChange={handleFormChange("name")}
              />
              <InputSelect
                items={selectCategoryItems}
                selectedValue={formState.category?.id || ""}
                label={t("Category")}
                id="category-select"
                containerClassName={defaultMargin}
                className={defaultWidth}
                isRequired={true}
                onChange={onSelectChange("category")}
              />
              <InputField
                required={true}
                containerClassNames={classNames(defaultMargin, defaultWidth)}
                className="w-full"
                id="name-field"
                value={formState.description}
                type="text"
                label={t("Short description")}
                onChange={handleFormChange("description")}
              />
            </div>
            <div className="mb-8">
              <RadioButtons
                id="target-group-select"
                name="target-group"
                label={t("Target Group")}
                options={generateGenderOptions()}
                defaultValue="all"
                value={formState.gender}
                isRequired={true}
                onChange={onSelectChange("gender")}
              />
              <div className="mt-2">
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.lgbtqi}
                        onClick={handleFormChange("lgbtqi")}
                      />
                    }
                    label={t("LGBTQI")}
                    value={formState.lgbtqi}
                    labelPlacement="end"
                  />
                </FormGroup>
              </div>
            </div>
            <div className="mb-8">
              <FieldLabel>{t("Age")}</FieldLabel>
              <div className={classNames("flex", defaultMargin, defaultWidth)}>
                <InputField
                  containerClassNames="w-1/2 mr-2"
                  className="w-full"
                  required={true}
                  id="age-from-field"
                  value={formState.minAge}
                  type="number"
                  label={t("From")}
                  error={!formState.minAge}
                  onChange={handleFormChange("minAge")}
                />
                <InputField
                  containerClassNames="w-1/2"
                  className="w-full"
                  required={true}
                  id="age-to-field"
                  value={formState.maxAge}
                  type="number"
                  label={t("To")}
                  error={!formState.maxAge}
                  onChange={handleFormChange("maxAge")}
                />
              </div>
            </div>
            <div className="mb-8">
              <RadioButtons
                id="recurring_event-select"
                name="recurring_event-group"
                label={`${t("When")}?`}
                options={generateRecurringOptions()}
                value={formState.recurring_event ? "regular" : "one-time"}
                isRequired={true}
                onChange={onSelectChange("recurring_event")}
              />
              {formState.recurring_event && (
                <CheckboxSelect
                  options={generateDayOptions()}
                  value={formState.days}
                  defaultChecked={1}
                  onChange={onSelectChange("days")}
                />
              )}
            </div>
            <div
              className={classNames(
                defaultWidth,
                "flex",
                "justify-between",
                "mt-4",
                "mb-5"
              )}
            >
              <div className="w-1/2 pr-5">
                <CustomDatePicker
                  className="w-full"
                  value={dayjs(formState.start_date)}
                  onChange={onDateChange("start_date")}
                  placeholder={t("From")}
                  isRequired={true}
                  slotProps={{
                    textField: () => ({
                      error: Boolean(formErrorState.start_date),
                    }),
                  }}
                />
              </div>
              <div className="w-1/2 pl-5">
                <CustomDatePicker
                  className="w-full"
                  value={dayjs(formState.end_date)}
                  onChange={onDateChange("end_date")}
                  placeholder={t("To")}
                  isRequired={true}
                  slotProps={{
                    textField: () => ({
                      error: Boolean(formErrorState.end_date),
                    }),
                  }}
                />
              </div>
            </div>
            <div className={classNames(defaultWidth, "mb-8")}>
              <MultiInputTimeRangeField
                value={[start_time, end_time]}
                onChange={onTimeChange("time")}
                sx={dataPickerSx}
              />
            </div>
            <div className="w-full my-4 mb-8">
              <RadioButtons
                id="price-select"
                name="price-group"
                label={t("Price")}
                options={generatePriceOptions()}
                value={formState.price}
                isRequired={true}
                onChange={onSelectChange("price")}
              />
              {formState.price !== "free" && (
                <InputField
                  containerClassNames={classNames(defaultMargin, "mt-2")}
                  className={defaultWidth}
                  id="price-field"
                  value={formState.price}
                  type="text"
                  label={t("Euro")}
                  onChange={handleFormChange("price")}
                />
              )}
            </div>
            <RadioButtons
              id="is-event-for-school-select"
              name="event-for-school"
              label={t("Klub for School")}
              options={generateKlubForSchoolOptions()}
              value={formState.isSchoolEvent}
              isRequired={true}
              onChange={onSelectChange("isSchoolEvent")}
            />
          </div>
          <div className="w-full sm:w-1/2 pl-0 sm:pl-6">
            <ImagePicker
              title={t("Hero Photo")}
              description={t("Image picker description")}
              formatText={t("Image format")}
              tooltipText={t("IA-photo")}
              filesContent={filesContent}
              image={formState.image.url}
              isRequired={true}
              onClick={onFileChange}
            />
            <div className="flex sm:hidden mt-6 mb-4 w-full">
              <div className="w-1/2 mr-2">
                <Button
                  className="w-full"
                  text={t("Back")}
                  loading={formLoading}
                  variant="secondary"
                  onClick={onBack}
                />
              </div>
              <div className="w-1/2 ml-2">
                <Button
                  className="w-full"
                  text={t("Save")}
                  loading={formLoading}
                  onClick={onSave}
                />
              </div>
            </div>
          </div>
        </form>
      </PageContainer>
      <ToastContainer />
    </>
  );
};
