import React, { ReactElement } from "react";
import { Typography, Button as MuiButton } from "@mui/material";
import Button from "../Button";
import ArrowBack from "../../img/icons/arrow_back.svg";
import classNames from 'classnames';

interface PageHeaderProps {
  title: string;
  btnTitle?: string;
  actionLoading?: boolean;
  hideBtnOnMobile?: boolean;
  subTitle?: string;
  children?: ReactElement;
  onBtnClick?: React.MouseEventHandler<HTMLButtonElement>;
  onBack?: () => void;
}

const HeaderTitle = ({ title, subTitle }: { title: string; subTitle?: string }) => (
  <div className="flex flex-col">
    <Typography fontWeight={500} variant="h5">{title}</Typography>
    {subTitle && <Typography>{subTitle}</Typography>}
  </div>
);

export const PageHeader = ({
  title,
  btnTitle,
  subTitle,
  actionLoading = false,
  hideBtnOnMobile = false,
  children,
  onBtnClick,
  onBack,
}: PageHeaderProps) => {
  const btnClassNames = hideBtnOnMobile ? "hidden sm:block" : "block";
  return (
    <div className="w-full flex flex-col sm:flex-row justify-between items-center pl-4 pr-4 pt-4 pb-4 mb-4 border-b border-gray50">
      <div className="sm:w-1/3">
        {!!onBack ? (
          <div className="flex mb-4 sm:mb-0">
            <MuiButton onClick={onBack}>
              <img className="min-w-8 min-h-8" src={ArrowBack} alt="back"></img>
            </MuiButton>
            <HeaderTitle title={title} subTitle={subTitle} />
          </div>
        ) : (
          <div className="mb-4 sm:mb-0">
            <HeaderTitle title={title} subTitle={subTitle} />
          </div>
        )}
      </div>
      <div className="sm:w-2/3 flex items-center justify-end">
        {children}
        {!!btnTitle && (
          <div className={classNames(btnClassNames, 'ml-4', 'justify-end')}>
            <Button
              text={btnTitle}
              loading={actionLoading}
              onClick={onBtnClick}
            />
          </div>
        )}
      </div>
    </div>
  );
};
