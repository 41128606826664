import React from "react";
import {
  TextField,
  InputAdornment,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { colors } from '../../constants/styles';
import FieldLabel from '../FieldLabel';

interface InputFieldProps {
  value: string;
  id: string;
  className?: string;
  containerClassNames?: string;
  placeholder?: string;
  type?: React.HTMLInputTypeAttribute;
  label: string;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
  startText?: string;
  size?: "small" | "medium";
  multiline?: boolean;
  rows?: number;
  required?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

export const CustomTextField = styled(TextField)({
  "& label": {
    fontWeight: 600,
  },
  "& label.Mui-focused": {
    color: "black",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    backgroundColor: "white",
    borderColor: colors.borderGrey,
    borderSize: 2,
    "&:hover fieldset": {
      borderColor: colors.primary,
    },
    "&.Mui-focused fieldset": {
      borderColor: colors.primary,
    },
    "&.Mui-focused": {
      borderColor: colors.primary,
    },
  },
});

const disabledStyles = {
  "& .MuiInputBase-root": { background: colors.highlight },
};

export const InputField = ({
  value,
  id,
  className = "",
  containerClassNames = "",
  placeholder = "",
  type = "text",
  error = false,
  helperText = "",
  label,
  disabled = false,
  startText = "",
  size = "small",
  multiline = false,
  rows,
  required = false,
  onChange,
  onFocus = () => {},
}: InputFieldProps) => {
  const placeholderOrLabel = placeholder || label;
  return (
    <div className={`${containerClassNames}`}>
      <FieldLabel isRequired={required}>{label}</FieldLabel>
      <CustomTextField
        id={id}
        variant="outlined"
        className={className}
        placeholder={placeholderOrLabel}
        value={value}
        type={type}
        error={error}
        helperText={helperText}
        disabled={disabled}
        size={size}
        multiline={multiline}
        rows={rows}
        onChange={onChange}
        onFocus={onFocus}
        sx={disabled ? disabledStyles : {}}
        InputProps={
          startText
            ? {
                startAdornment: (
                  <InputAdornment position="start">{startText}</InputAdornment>
                ),
              }
            : undefined
        }
      />
    </div>
  );
};
