import { colors } from '../../constants/styles';

export const buttonStyles = {
  contained: {
    background: colors.primary,
    "&:hover": {
      background: colors.primaryDark,
      "box-shadow": "none",
    },
  },
  text: {
    color: colors.grey,
  },
  outlined: {
    color: colors.primary,
  },
  smallGrey: {
    background: colors.highlight,
    padding: "0px 8px",
    color: colors.black,
    height: "24px",
    "&:hover": {
      background: colors.borderGrey,
      "box-shadow": "none",
    },
  },
  secondary: {
    background: colors.highlight,
    color: colors.black,
    "&:hover": {
      background: colors.borderGrey,
      "box-shadow": "none",
    },
  },
};