import React, { useEffect } from "react";
import Navigation from "./navigation";
import { AuthProvider } from "./context/AuthContext";
import { UserProfileProvider } from "./context/UserProfileContext";
import { EventsProvider } from "./context/EventsContext";
import { CategoriesProvider } from "./context/CategoriesContext";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "dayjs/locale/de";
import { LocationsProvider } from "./context/LocationsContext";
import { AreasProvider } from "./context/AreaContext";
import { ParticipationProvider } from './context/ParticipationContext';
import { ThemeProvider } from "@mui/material/styles";

import "@fontsource/inter";
import theme from './theme';

function App() {

  useEffect(() => {
    document.title = "Klub";
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <UserProfileProvider>
        <AuthProvider>
          <EventsProvider>
            <ParticipationProvider>
              <CategoriesProvider>
                <AreasProvider>
                  <LocationsProvider>
                    <LocalizationProvider
                      adapterLocale="de"
                      dateAdapter={AdapterDayjs}
                    >
                      <Navigation />
                    </LocalizationProvider>
                  </LocationsProvider>
                </AreasProvider>
              </CategoriesProvider>
            </ParticipationProvider>
          </EventsProvider>
        </AuthProvider>
      </UserProfileProvider>
    </ThemeProvider>
  );
}

export default App;
