import { Tooltip, Typography } from "@mui/material";
import UploadImage from "../../img/icons/upload.svg";
import { FileContent } from "use-file-picker/dist/interfaces";
import classNames from "classnames";
import CameraIcon from "../../img/icons/camera.svg";
import FieldLabel from '../FieldLabel';
import { useTranslation } from 'react-i18next';

interface ImagePickerProps {
  title: string;
  description: string;
  formatText: string;
  tooltipText?: string;
  filesContent: FileContent<string>[];
  image?: string;
  isRequired?: boolean;
  containerClassName?: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export const ImagePicker = ({
  title,
  description,
  formatText,
  tooltipText = '',
  filesContent,
  image = "",
  isRequired = false,
  containerClassName,
  onClick,
}: ImagePickerProps) => {
  const { t } = useTranslation();
  const btnClass = classNames(
    { "py-20": !filesContent.length },
    { "px-10": !filesContent.length },
    "flex",
    "flex-col",
    "justify-center",
    "items-center",
    "rounded-xl",
    "mt-6",
    "bg-highlight",
    "min-h-80",
    "w-full"
  );

  return (
    <div className={classNames("w-full",containerClassName)}>
      <div className="flex flex-row items-center">
        {title && <FieldLabel isRequired={isRequired}>{title}</FieldLabel>}
      </div>
      <button onClick={onClick} className={btnClass}>
        {filesContent.length ? (
          <>
            {filesContent.map((file, index) => (
              <div key={index}>
                <img
                  className="max-h-96 rounded-xl"
                  alt={file.name}
                  src={file.content}
                ></img>
              </div>
            ))}
          </>
        ) : image ? (
          <div>
            <img
              className="max-h-96 rounded-xl"
              alt="firebase-storage"
              src={image}
            ></img>
          </div>
        ) : (
          <>
            <img src={UploadImage} alt="Upload" />
            <Typography marginTop={2} color="grey">
              {description}
            </Typography>
            <Typography marginTop={4} color="grey">
              {formatText}
            </Typography>
          </>
        )}
      </button>
      {!!tooltipText && (
        <div className="mt-4 flex items-center">
          <img src={CameraIcon} alt="Camera" className="mr-2" />
          <Typography>{t("You don't have any photo?")}</Typography>
          <Tooltip title={tooltipText}>
            <p className="ml-1 text-primary">{t("Create with AI")}</p>
          </Tooltip>
        </div>
      )}
    </div>
  );
};
