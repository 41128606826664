import { FormControl } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LocationFormFields } from '../../types/forms';
import FieldLabel from '../FieldLabel';
import InputField from '../InputField';
import { ChangeEvent } from 'react';

interface TimeRangeProps {
  id: string;
  label: string;
  className: string;
  fromValue: string;
  toValue: string;
  fromName: LocationFormFields;
  toName: LocationFormFields;
  error?: boolean;
  isRequired?: boolean;
  onValueChanged: (
    name: LocationFormFields
  ) => (event: ChangeEvent<HTMLInputElement>) => void;
}

export const TimeRange = ({
  id,
  label,
  className,
  fromValue,
  toValue,
  fromName,
  toName,
  isRequired = false,
  error = false,
  onValueChanged,
}: TimeRangeProps) => {
  const { t } = useTranslation();
  return (
    <div className="mt-4">
      <FormControl className={className}>
        {label && <FieldLabel isRequired={isRequired}>{label}</FieldLabel>}
        <div className="flex flex-row">
          <InputField
            containerClassNames="w-1/2 mr-2"
            className="w-full"
            required={true}
            id={`from-${id}`}
            value={fromValue}
            type="number"
            label={t("From")}
            error={error}
            onChange={onValueChanged(fromName)}
          />
          <InputField
            containerClassNames="w-1/2 mr-2"
            className="w-full"
            required={true}
            id={`to-${id}`}
            value={toValue}
            type="number"
            label={t("To")}
            error={error}
            onChange={onValueChanged(toName)}
          />
        </div>
      </FormControl>
    </div>
  );
};
