import { Typography } from '@mui/material';

interface IProps {
  isRequired: boolean;
  children: JSX.Element | string | undefined;
}


export const FieldLabel = ({ isRequired, children }: IProps) => {
  return (
    <div className="mb-1 flex items-center">
      {isRequired && <span className='mr-1 text-required'>•</span>}
      <Typography fontWeight={600} variant="body2">
        {children}
      </Typography>
    </div>
  );
};
