import React from "react";
import { Navigate } from "react-router-dom";
import SideBar from "../components/SideBar";
import { Nullable } from '../types/utility';
import { UserRole } from '../context/AuthContext';

interface PrivateRouteProps {
  component: React.ComponentType<any>;
  path: string;
  isSidebar?: boolean;
  isAuthenticated: () => boolean;
  allowedRoles?: UserRole[];
  userRole: Nullable<UserRole>;
  privateRedirectRout: string;
}

export const PrivateRoute = ({
  component: Component,
  isSidebar = true,
  privateRedirectRout,
  isAuthenticated,
  allowedRoles = [],
  userRole,
  ...rest
}: PrivateRouteProps) => {
  if (!isAuthenticated()) {
    return <Navigate to="/login" />;
  }

  // Restrict access if the user doesn't have the required role
  if (allowedRoles.length > 0 && !allowedRoles.includes(userRole!)) {
    return <Navigate to={privateRedirectRout} />;
  }

  return (
    <>
      {isSidebar ? (
        <SideBar>
          <Component {...rest} />
        </SideBar>
      ) : (
        <Component {...rest} />
      )}
    </>
  );
};
