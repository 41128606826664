import React, { ReactNode } from "react";
import { Box, Select, MenuItem, Typography, IconButton } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import { useTranslation } from 'react-i18next';

interface PaginationProps {
  count: number;
  rowsPerPage: number;
  rowsPerPageOption: number[],
  page: number;
  onPageChange: (newPage: number) => void;
  onRowsPerPageChange: (
    event: SelectChangeEvent<number>,
    child: ReactNode
  ) => void;
}

export const CustomPagination: React.FC<PaginationProps> = ({
  count,
  rowsPerPage,
  rowsPerPageOption,
  page,
  onPageChange,
  onRowsPerPageChange,
}) => {
    const { t } = useTranslation();
  const totalPages = Math.ceil(count / rowsPerPage);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={{ padding: "10px" }}
    >
      {/* Dropdown: Rows per page */}
      <Box display="flex" alignItems="center">
        <Typography variant="body2">{t("Rows per page")}</Typography>
        <Select
          value={rowsPerPage}
          onChange={onRowsPerPageChange}
          sx={{ marginLeft: "10px", '& .MuiOutlinedInput-input': { padding: '8px 8px' } }}
        >
          {rowsPerPageOption.map((option) => (
            <MenuItem
              key={option}
              value={option}
            >
              {option}
            </MenuItem>
          ))}
        </Select>
      </Box>

      {/* Pagination Info */}
      <Typography variant="body2">
        {page * rowsPerPage + 1}-{Math.min((page + 1) * rowsPerPage, count)} of{" "}
        {count}
      </Typography>

      {/* Navigation Buttons */}
      <Box display="flex" alignItems="center">
        <IconButton
          onClick={() => onPageChange(page - 1)}
          disabled={page === 0}
        >
          <ArrowBackIos fontSize="small" />
        </IconButton>
        <IconButton
          onClick={() => onPageChange(page + 1)}
          disabled={page >= totalPages - 1}
        >
          <ArrowForwardIos fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  );
};
