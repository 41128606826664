import { CheckboxProps, Checkbox as MuiCheckbox } from "@mui/material";
import { colors } from '../../constants/styles';

export const Checkbox = ({ ...props }: CheckboxProps) => {
  return (
    <MuiCheckbox
      {...props}
      size="small"
      sx={{
        color: colors.primary,
        "&.Mui-checked": {
          color: colors.primary,
        },
      }}
    />
  );
};
